'use client'
import { FC } from 'react'
import Link from 'next/link'

import { Project } from 'common/project'
import DownloadOnApple from 'components/svgs/DownloadOnApple'
import DownloadOnGoogle from 'components/svgs/DownloadOnGoogle'

interface Props {
  children: React.ReactNode
}

const HomeTemplate: FC<Props> = ({ children }) => {
  return (
    <div className='container'>
      <div className='row pt-20'>
        <div className='d-none d-lg-block col-3'>
          <div className='card py-9 px-6'>
            <h6 className='text-primary'>The Justfix Promise</h6>
            <span>
              We send you the best-qualified and closest fixer, and we promise
              to respond immediately. All work comes with our 12 month
              guarantee; so whatever we fix, stays fixed.
            </span>
          </div>
          <div className='download-app pb-6 mt-5'>
            <h6>
              Download our <span className='text-primary'>App</span>
            </h6>
            <Link href={Project.googleUrl}>
              <DownloadOnGoogle className='mb-5' />
            </Link>
            <Link href={Project.appleUrl}>
              <DownloadOnApple />
            </Link>
          </div>
        </div>
        <div className='col overflow-x-hidden ps-lg-17'>{children}</div>
      </div>
    </div>
  )
}

export default HomeTemplate
